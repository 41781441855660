



























































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";

@Component({})
export default class AUser extends Vue {
  // 字典
  config = store.state.system.config;
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search = {
    channelId: "",
    bidMonth: "",
    sendingOrganizationCode: [],
    tradingCenterCode: [],
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("J30101", {
        ...this.search,
        tradingCenterCode: this.search.tradingCenterCode.join(","),
        sendingOrganizationCode: this.search.sendingOrganizationCode.join(","),
      })
      .then((data) => {
        this.total = data.total;
        this.tableData = data.list;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  // 获取出函机构
  org: any = {}
  getOrganization(){
    api.post('A13006', {bond_type: '', channel_no: '', area_id: ''}).then(res => {
      this.org = res
    })
  }

  //成本
  hesuanDialog = false
  hesuanInfo: any = {}
  hsRadio = '1'
  cost = ''
  toChengben(item: any) {
    this.hesuanInfo = item
    this.hesuanDialog = true
    console.log(item);
  }
  add() {
    let obj = {
      cost: this.hsRadio == '1' ? this.cost : '-' + this.cost,
      bidMonth: this.hesuanInfo.bidMonth,
      tradingCenterCode: this.hesuanInfo.tradingCenterCode,
      sendingOrganizationCode: this.hesuanInfo.sendingOrganizationCode,
      channelId: this.hesuanInfo.channelId
    }
    api.post('J30401', obj).then(res => {
      this.$message.success('操作成功')
      this.cost = ''
      this.hesuanDialog = false
      this.searchTable()
    })
  }
  cancle() {
    this.cost = ''
    this.hsRadio = '1'
    this.hesuanDialog = false
  }
  onInput0(e: any) {
    // 验证是否是纯数字
    let isNumber = /^[0-9]+.?[0-9]*$/.test(e.target.value);
    // 过滤非数字
    e.target.value = e.target.value.replace(/[^\d^\.]+/g, "")
            .replace(/^0+(\d)/, "$1")
            .replace(/^\./, "0.")
            .match(/^\d*(\.?\d{0,2})/g)[0] || "";
    if (!isNumber || e.target.value < 0) {
      this.$message.warning("只能输入大于0的数字");
    }
    // e.target.value = (e.target.value >= 0 && e.target.value.match(/^\d*/g)[0]) || null;
  }

  // 核算明细
  hesuan(item: any) {
    this.$router.push({
      name: "businessDetail",
      query: {
        channelId: item.channelId,
        bidMonth: item.bidMonth,
        tradingCenterCode: item.tradingCenterCode,
        sendingOrganizationCode: item.sendingOrganizationCode,
      },
    });
  }
  goHesuan(item: any) {
    this.$router.push({
      name: "orderBusiness",
      query: {
        channelId: item.channelId,
        tradingCenterCode: item.tradingCenterCode,
        sendingOrganizationCode: item.sendingOrganizationCode,
        channelName: item.channelName,
        bidMonth: item.bidMonth,
      },
    });
  }
  // 交易中心
  channelList = {};
  getChannelDict() {
    api.post("A13005").then((res) => {
      this.channelList = res;
    });
  }

  activated() {
    this.searchTable();
    this.getChannelDict();
    this.getOrganization()
  }
}
